import React, { createContext, useState } from 'react';

import useLang from '../hooks/useLang';

export const LangContext = createContext('is');

export default ({ children }) => {
  const [lang, toggleLang] = useLang();

  return <LangContext.Provider value={{ lang, toggleLang }}>{children}</LangContext.Provider>;
};
