import { useEffect, useState } from 'react';

export default () => {
  const [lang, setLang] = useState('is');

  const toggleLang = () => {
    if (lang === 'is') {
      window.localStorage.setItem('lang', 'en');
      setLang('en');
    } else {
      window.localStorage.setItem('lang', 'is');
      setLang('is');
    }
  };

  useEffect(() => {
    const localLang = window.localStorage.getItem('lang');
    if (localLang) {
      window.localStorage.setItem('lang', localLang);
      setLang(localLang);
    } else {
      setLang('is');
    }
  }, []);

  return [lang, toggleLang];
};
